<template>
  <div class="row">
    <!-- begin::Overview -->
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-header flex-wrap">
          <div class="card-title">
            <h3 class="card-label">
              Overview
              <span class="text-muted pt-2 font-size-sm d-block">Companies associated to this company.</span>
            </h3>
          </div>

          <div class="card-toolbar">
            <router-link v-if="$auth.hasPermission('companies.create')" to="/companies/create" class="btn btn-primary w-175px font-weight-bolder">
              <span class="svg-icon align-middle">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span>Add company</span>
            </router-link>
          </div>
        </div>

        <div class="card-body">
          <!-- begin::Datatable actions -->
          <div class="mb-7">
            <div class="row align-items-center">
              <div class="col-12 col-lg">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    @keyup.enter="$refs.datatable.search"
                    @keyup="searchDebounced"
                  >
                  <span>
                    <i>
                      <font-awesome-icon :icon="['fas', 'search']" />
                    </i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end::Datatable actions -->

          <!-- begin::Datatable -->
          <datatable
            ref="datatable"
            :url="datatable.url"
            :fields="datatable.fields"
            :search-fields="datatable.searchFields"
            :on-pagination-data="onPaginationData"

            :filters="datatable.filters"

            :transform="transform"
          >
            <!-- begin::Name -->
            <div slot="name" slot-scope="props">
              <a class="text-brand">
                {{ (props.rowData.name) }}
              </a>
            </div>
            <!-- end::Name -->

            <!-- begin::Actions -->
            <div slot="actions" slot-scope="props">
              <b-dropdown
                v-if="
                  ($auth.hasPermission('companies.update') && $auth.hasPermission('companies.get.all'))
                  || ($auth.isResourceFromIndex('users') && hasAccessToCompany(props.rowData) === true)
                  || ($auth.hasPermission('companies.access'))
                  || ($auth.hasPermission('companies.delete'))
                "
                ref="actions-dropdown"
                variant="clean btn-sm btn-icon"
                class="dropdown-inline mr-2"
                right
                lazy
                no-caret
                boundary="window"
                menu-class="dropdown-menu-md"
                :disabled="props.rowData._meta.guid === $auth.resource._meta.guid"
              >
                <template v-slot:button-content>
                  <span class="svg-icon">
                    <font-awesome-icon :icon="['fas', 'cog']" />
                  </span>
                </template>

                <template v-slot:default>
                  <ul class="navi flex-column navi-hover py-2">
                    <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                      Choose an action:
                    </li>

                    <li class="navi-separator my-0" />

                    <template v-if="$auth.isResourceFromIndex('users') && $auth.hasPermission('companies.update') && $auth.hasPermission('companies.get.all')">
                      <li class="navi-item">
                        <a class="navi-link cursor-pointer" @click.prevent="modifyCompanyID(props.rowData)">
                          <span class="navi-icon">
                            <i>
                              <font-awesome-icon :icon="['fas', 'hashtag']" class="w-15px h-15px" />
                            </i>
                          </span>
                          <span class="navi-text">Change company ID</span>
                        </a>
                      </li>
                    </template>

                    <template v-if="props.rowData._meta.guid !== currentCompany._meta.guid && (hasAccessToCompany(props.rowData) === true)">
                      <li class="navi-separator my-0" />

                      <li class="navi-item">
                        <a class="navi-link cursor-pointer" @click.prevent="switchToCompany(props.rowData)">
                          <span class="navi-icon">
                            <i>
                              <font-awesome-icon :icon="['fas', 'sign-in-alt']" class="w-15px h-15px" />
                            </i>
                          </span>
                          <span class="navi-text">Switch to company</span>
                        </a>
                      </li>
                    </template>

                    <template v-if="props.rowData._meta.guid !== currentCompany._meta.guid && $auth.hasPermission('companies.access') && (hasAccessToCompany(props.rowData) === false)">
                      <li class="navi-separator my-0" />

                      <li class="navi-item">
                        <a class="navi-link cursor-pointer" @click.prevent="gainAccessToCompany(props.rowData)">
                          <span class="navi-icon">
                            <i>
                              <font-awesome-icon :icon="['fas', 'lock-alt']" class="w-15px h-15px" />
                            </i>
                          </span>
                          <span class="navi-text">Gain access</span>
                        </a>
                      </li>
                    </template>

                    <template v-if="props.rowData._meta.guid !== currentCompany._meta.guid && ($auth.isResourceFromIndex('users') && $auth.hasPermission('companies.delete'))">
                      <li class="navi-separator my-0" />

                      <li class="navi-item">
                        <a class="navi-link cursor-pointer" @click.prevent="deleteCompany(props.rowData)">
                          <span class="navi-icon">
                            <i>
                              <font-awesome-icon :icon="['fas', 'trash']" class="w-15px h-15px" />
                            </i>
                          </span>
                          <span class="navi-text">Delete company</span>
                        </a>
                      </li>
                    </template>
                  </ul>
                </template>
              </b-dropdown>
            </div>
            <!-- end::Actions -->
          </datatable>
          <!-- end::Datatable -->

          <!-- begin::Datatable footer -->
          <div class="datatable datatable-default">
            <div class="datatable-pager datatable-paging-loaded">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />

              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>
          <!-- end::Datatable footer -->
        </div>
      </div>
    </div>
    <!-- end::Overview -->
  </div>
</template>

<script>
// Mixins
import { DatatableMixin } from '@vedicium/metronic-vue';

// Libraries
import { CompanyClass as Company, company as companyLib } from '@vedicium/core-vue/build/modules/company';
import { mutations as mutationsLib } from '@vedicium/core-vue';

export default {
  mixins: [DatatableMixin],
  data () {
    return {
      currentCompany: companyLib.current,
      datatable: {
        url: Company.uri,

        fields: [{
          name: 'name',
          title: 'Name',
          sortField: 'name',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
        }, {
          name: 'company_id',
          title: 'Company ID',
          sortField: 'company_id',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
        }, {
          name: 'company_type',
          title: 'Company type',
          sortField: 'company_type',
          formatter: (value) => this.$tu(`companies.type.${value}.name`) || value,
          titleClass: 'w-200px',
          dataClass: 'w-200px',
        }, {
          name: 'location.description',
          title: 'Location',
          sortField: 'location.description',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
          formatter (value) {
            return `${value || '<span class="font-italic">No location set</span>'}`;
          },
        }, {
          name: 'actions',
          title: 'Actions',
          titleClass: 'w-125px',
          dataClass: 'w-125px',
        }],

        searchFields: ['name', 'company_id', 'company_type', 'location.description'],

        filters: {},
      },
    };
  },

  mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Companies' }]);
  },

  methods: {
    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => new Company().merge(row));
      }
      return response;
    },

    modifyCompanyID (company = null) {
      if (!company || company instanceof Company === false) {
        return;
      }

      const vm = this;
      vm.$eventhub.emit('modals:layout:document:modify-field:open', {
        title: 'Change company ID',
        label: 'Company ID',
        label_icon: ['fas', 'hashtag'],
        value: company.company_id,
        async onSubmit (value) {
          const companyClone = company.clone().merge({ company_id: value });

          const mutations = mutationsLib.create(['company_id'], company.formatBody('update'), companyClone.formatBody('update'));
          if (mutations.length > 0) {
            try {
              await company.update(mutations);
              vm.$refs.datatable.refresh({ query: { disable_cache: true } });
            } catch (e) {
              vm.$errors.handle(e, { component: vm, ui_element: true });
              console.error(e);
            }
          }
        },
      });
    },

    hasAccessToCompany (company = null) {
      return (this.$auth.isResourceFromIndex('users') && (this.$auth.resource.company_permissions || []).filter((row) => row.invite_pending !== true, []).map((row) => row.company_guid, []).includes(company._meta.guid) === true);
    },

    switchToCompany (company = null) {
      if (!company || company instanceof Company === false) {
        return;
      }

      if (this.$auth.isResourceFromIndex('users')) {
        if ((this.$auth.resource.company_permissions || []).map((row) => row.company_guid, []).includes(company._meta.guid) === false) {
          throw new Error('Company access denied');
        }
      }

      companyLib.setCompany(company._meta.guid);
      this.$router.push('/dashboard');
    },

    gainAccessToCompany (company = null) {
      if (!company || company instanceof Company === false) {
        return;
      }

      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: `Gain access to ${company.name}`,
        message: `Are you sure that you want to gain access to <strong>${company.name}</strong>?<br />Please note that users of the company can see you've gained access.`,
        variant: 'info',
        buttonText: 'Gain access',
        async onSubmit () {
          try {
            await vm.$ws.put(`${Company.uri}/${company._meta.guid}/access`);
            vm.$auth.checkAuthenticated(); // Check authenticated, so the resource details will be reloaded
            companyLib.setCompany(company._meta.guid);
            vm.$router.push('/dashboard');
          } catch (e) {
            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
          }
        },
      });
    },

    deleteCompany (company = null) {
      if (!company || company instanceof Company === false) {
        return;
      }

      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Delete company',
        message: `Are you sure that you want to delete <strong>${company.name}</strong>?<br />Please note that nobody will have access to this company after it's deleted.<br /><br />Please type the name of the company to confirm:`,
        verifyString: company.name,
        async onSubmit () {
          try {
            await vm.$ws.delete(`${Company.uri}/${company._meta.guid}`);
            vm.$refs.datatable.reload({ query: { disable_cache: true } });
          } catch (e) {
            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
          }
        },
      });
    },
  },
};
</script>
